<template>
  <div class="intro">
      <div class="step-title">&nbsp;&nbsp;安装包下载中<span v-html=" dynamicPoint || '.&nbsp;&nbsp;'"></span></div>
      <div class="step-box">
        • 杀毒软件可能会误报警，请允许执行
        <br>
        <br>
        • 最低要求 Windows 7 ‑ Service Pack 1 ‑ 64 bit <br>
        并安装补丁 <a href="/patch/win7sp1x64%E7%94%A8%E6%88%B7%E8%AF%B7%E5%85%88%E7%82%B9%E8%BF%99%E4%B8%AA.msu">win7sp1x64用户请先点这个.msu</a>
        <br>
        <br>
        <hr>
        如下载失败<br>
        尝试以下线路

        <br>
        <div v-if="lines.length > 0" class="lines">

          <template v-for="(line, index) in lines" :key="`line-item-win-${index}`">
            <a class="step-button" :href="`${line.domains[0]}/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`"
              v-html="line.lineName" :style="{order: line.order}"
            >
            </a>
          </template>
        </div>

        <div v-else class="lines">
          <a :href="`https://goj123.com/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`" class="step-button">中国线路1</a>

          <a :href="`https://gojapp.org/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`" class="step-button">中国线路2</a>

          <a :href="`https://goj.life/${store.env.app}/exes/${store.env.version}/${store.env.app}-${store.env.version}-release-${store.env.channel}.exe`" class="step-button">&nbsp;欧美线路&nbsp;</a>
        </div>
        <br>
        <hr class="pl-harmonyos" v-if="isHarmonyOS">
        <div class="pl-harmonyos" v-if="isHarmonyOS">
          <a :href="`guide_HarmonyOS.html?from=${store.env.channel}`">鸿蒙安装帮助</a>
        </div>
      </div>
  </div>
</template>

<script>
import {ref, onMounted, reactive} from 'vue'
  import { useStore } from 'vuex'
  import utils from "@/utils/utils";
  import axios from "axios";
import {getDomain} from "@/config";

  export default {
    name: 'Windows',
    components: {},
    setup () {
      let store = useStore().state
      const lines = reactive([])

      const points = [
        '.&nbsp;&nbsp;',
        '..&nbsp;',
        '...'
      ]
      let dynamicPoint = ref(points[0])
      let isHarmonyOS = ref(false)

      function dynamic () {
        setInterval(function () {
          let c = dynamicPoint.value.split('.').length - 1
          if (c == 3) {
            dynamicPoint.value = points[0]
          } else if (c == 2) {
            dynamicPoint.value = points[2]
          } else {
            dynamicPoint.value = points[1]
          }
        }, 1000)
      }
      function showHarmonyOS () {
        if (utils.getIsHM()) {
          isHarmonyOS.value = true
        }
      }

      function loadLine(){
        axios.get(`${getDomain('oc')}/api/lines`)
            .then(res => {
              if (!(res.data)) return

              for (let i = 0; i < res.data.length; i++) {
                  res.data[i]['lineName'] =  res.data[i].name === '海外线路'
                      ? '&nbsp;欧美线路&nbsp;'
                      : res.data[i].name === '国内推广'
                          ? '中国线路2'
                          : '中国线路1'
                  res.data[i]['order'] =  res.data[i].name === '海外线路'
                      ? '3'
                      : res.data[i].name === '国内推广'
                          ? '2'
                          : '1'
                lines.push(res.data[i])
              }
            })
        .catch(() => {
        })
      }


      onMounted(()=>{
        dynamic()
        showHarmonyOS();
        utils.statsDownload("windows");
        loadLine();
      })

      return {
        dynamicPoint,
        isHarmonyOS,
        store,
        lines
      }
    }
  }
</script>

<style scoped>

  .intro .step-title {
    background-color: #CC1424;
    color: white;
    width: 70%;
    max-width: 500px;
    margin: 0 auto 1.2rem auto;
    padding: 0.25rem;
    font-size: 1.5rem;
    border: 3px solid #4D2C22;
    border-radius: 10rem;
    box-shadow: 0 4px 0 0 #00000030;
    -webkit-box-shadow: 0 4px 0 0 #00000030;
    -moz-box-shadow: 0 4px 0 0 #00000030;
  }

  .intro .step-box {
    display: block;
    background-color: #EEEEEE;
    color: black;
    width: 70%;
    max-width: 500px;
    margin: 0 auto 1.2rem auto;
    padding: 3rem 0 1.5rem 0;
    font-size: 1.2rem;
    border: 3px solid #4D4D4D;
    border-radius: 2rem;
    box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
    -webkit-box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
    -moz-box-shadow: inset 0 4px 0 0 white, 0 4px 0 0 #00000030;
  }

  .intro .step-button, .intro a.step-button {
    background-color: #0066FF;
    border-radius: 20rem;
    border: none;
    color: white;
    padding: 0.2rem 1.2rem;
    display: inline-block;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none;
  }

  .intro .step-box hr {
    margin: 1.5rem;
    border: 0;
    border-top: 2px dotted #d3d3d3;
  }
  .lines {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .lines > a {
    margin-top: 20px;
  }
  @media all and (max-width: 376px){
    .intro .step-box {
      font-size: .9rem;
      padding: 2rem 8px;
      max-width: 90%;
      width: 88%;
    }
  }
  @media all and (max-width: 750px) and (min-width: 375px) {
    .intro .step-box {
      padding: 3rem 8px 1.5rem 8px;
      max-width: 90%;
      width: 88%;
    }
  }
</style>
